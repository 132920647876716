// import '../styles/index.scss';
// import '../../node_modules/normalize.css/normalize.css';
import Siema from './siema';

new Siema({
    selector: '.siema',
    duration: 300,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    multipleDrag: true,
    threshold: 20,
});

window.toggleMap = function (show, hide) {
    const elementShow = document.getElementsByClassName(`contacts__map-${show}`)[0];
    const elementHide = document.getElementsByClassName(`contacts__map-${hide}`)[0];

    if (elementShow.classList.value.indexOf('active') !== -1) {
        elementShow.classList.toggle('contacts__map-active');
        elementHide.classList.toggle('contacts__map-active');

        const elementMenuShow = document.getElementsByClassName(`contacts__block-${show}`)[0];
        const elementMenuHide = document.getElementsByClassName(`contacts__block-${hide}`)[0];
        const itemsTitles = document.querySelectorAll('div.contacts__mobile-title span');
        const itemsContent = document.querySelectorAll('div.contacts__mobile-text div');
        itemsTitles[0].classList.toggle('active');
        itemsTitles[1].classList.toggle('active');
        itemsContent[0].classList.toggle('active');
        itemsContent[1].classList.toggle('active');
        elementMenuShow.classList.toggle('contacts__block-active');
        elementMenuHide.classList.toggle('contacts__block-active');
    }
};

window.showPopup = function (index) {
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (w <= 1024) {
        const popup = document.getElementById('popup');
        const body = document.getElementsByTagName('body')[0];
        const item = document.getElementsByClassName('services__item_hover')[index];

        popup.innerHTML =
            '<div class="popup__content">' +
            item.innerHTML +
            '<span class="popup__close" onclick="window.hidePopup();"></span></div>';
        body.classList.toggle('disable__scroll');
        popup.classList.toggle('popup__show');
    }
};

window.showPopupTeam = function (index) {
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (w <= 1024) {
        const popup = document.getElementById('popup');
        const body = document.getElementsByTagName('body')[0];
        const item = document.getElementsByClassName('team__item_info_hover')[index];

        popup.innerHTML =
            '<div class="popup__content">' +
            item.innerHTML +
            '<span class="popup__close" onclick="window.hidePopup();"></span></div>';
        body.classList.toggle('disable__scroll');
        popup.classList.toggle('popup__show');
    }
};

window.hidePopup = function () {
    const popup = document.getElementById('popup');
    const body = document.getElementsByTagName('body')[0];

    body.classList.toggle('disable__scroll');
    popup.classList.toggle('popup__show');
};

function currentYPosition() {
    // Firefox, Chrome, Opera, Safari
    if (self.pageYOffset) return self.pageYOffset;
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop)
        return document.documentElement.scrollTop;
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) return document.body.scrollTop;
    return 0;
}

function elmYPosition(eID) {
    var elm = document.getElementById(eID);
    var y = elm.offsetTop;
    var node = elm;
    while (node.offsetParent && node.offsetParent != document.body) {
        node = node.offsetParent;
        y += node.offsetTop;
    }
    return y;
}

window.smoothScroll = function smoothScroll(eID) {
    // hide menu
    document.getElementById('mobile').classList.remove('menu__show');
    document.getElementById('fullpage').classList.remove('content__hide');

    var startY = currentYPosition();
    var stopY = elmYPosition(eID);
    var distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
        scrollTo(0, stopY);
        return;
    }
    var speed = Math.round(distance / 100);
    if (speed >= 20) speed = 20;
    var step = Math.round(distance / 25);
    var leapY = stopY > startY ? startY + step : startY - step;
    var timer = 0;
    if (stopY > startY) {
        for (var i = startY; i < stopY; i += step) {
            setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
            leapY += step;
            if (leapY > stopY) leapY = stopY;
            timer++;
        }
        return;
    }
    for (var i = startY; i > stopY; i -= step) {
        setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
        leapY -= step;
        if (leapY < stopY) leapY = stopY;
        timer++;
    }

    return false;
};

var fullpage = document.getElementById('fullpage');
var burgerBtn = document.getElementById('burgerBtn');
var mobile = document.getElementById('mobile');
burgerBtn.addEventListener(
    'click',
    function () {
        mobile.classList.toggle('menu__show');
        fullpage.classList.toggle('content__hide');
    },
    false,
);

var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
var movementStrength = 25;
var height = movementStrength / h;
var width = movementStrength / w;

const el = document.querySelector('#module');
el.addEventListener('mousemove', (e) => {
    let nw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let nh =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var pageX = e.offsetX - nw / 2;
    var pageY = e.offsetY - nh / 2;
    var newvalueX = (e.offsetX * 100) / w; // (500 * 100) / 1000
    var newvalueY = (e.offsetY * 100) / h;

    if (newvalueX <= 10) {
        newvalueX = 48;
    } else if (newvalueX > 10 && newvalueX <= 20) {
        newvalueX = 48.5;
    } else if (newvalueX > 20 && newvalueX <= 30) {
        newvalueX = 49;
    } else if (newvalueX > 30 && newvalueX <= 40) {
        newvalueX = 49.5;
    } else if (newvalueX > 40 && newvalueX <= 50) {
        newvalueX = 50;
    } else if (newvalueX > 50 && newvalueX <= 60) {
        newvalueX = 50;
    } else if (newvalueX > 60 && newvalueX <= 70) {
        newvalueX = 50.5;
    } else if (newvalueX > 70 && newvalueX <= 80) {
        newvalueX = 51;
    } else if (newvalueX > 80 && newvalueX <= 90) {
        newvalueX = 51.5;
    } else if (newvalueX > 90 && newvalueX <= 95) {
        newvalueX = 52;
    } else if (newvalueX > 95) {
        newvalueX = 52.5;
    }

    if (newvalueY <= 10) {
        newvalueY = 48;
    } else if (newvalueY > 10 && newvalueY <= 20) {
        newvalueY = 48.5;
    } else if (newvalueY > 20 && newvalueY <= 30) {
        newvalueY = 49;
    } else if (newvalueY > 30 && newvalueY <= 40) {
        newvalueY = 49.5;
    } else if (newvalueX > 40 && newvalueY <= 50) {
        newvalueY = 50;
    } else if (newvalueY > 50 && newvalueY <= 60) {
        newvalueY = 50;
    } else if (newvalueY > 60 && newvalueY <= 70) {
        newvalueY = 50.5;
    } else if (newvalueY > 70 && newvalueY <= 80) {
        newvalueY = 51;
    } else if (newvalueY > 80 && newvalueY <= 90) {
        newvalueY = 51.5;
    } else if (newvalueY > 90 && newvalueY <= 95) {
        newvalueY = 52;
    } else if (newvalueY > 95) {
        newvalueY = 52.5;
    }

    el.style.backgroundPosition = newvalueX + '%     ' + newvalueY + '%';
});
